<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 14:48:34
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-15 10:53:15
-->
<template>
  <div id="page-list">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <!-- <a-button @click="showModal" class="add-bt" type="primary">新增</a-button> -->
      <a-button class="add-bt" type="primary"><router-link to="/allocation/addOrEdit">新增</router-link></a-button>
    </Breadcrumb>
    <!-- 查询 -->
    <a-form
      ref="formRef"
      layout="inline"
      :model="formState"
    >
      <a-form-item>
        <a-input v-model="formState.name" placeholder="名称" />
      </a-form-item>

      <!-- <a-form-item>
        <a-select
          :allowClear="true"
          v-model="formState.code"
          :options="codeList"
          placeholder="页面类型"
          style="width: 180px"
        >
        </a-select>
      </a-form-item> -->

      <a-form-item>
        <a-button @click="((e)=>{queryData()})" type="primary" html-type="submit">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm">重置</a-button>
      </a-form-item>
    </a-form>
    <br />
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="false"
      :rowKey="(record) => record.id"
    >
      <span slot="name" slot-scope="text, record">
        <a>{{ text }}{{ record }}</a>
      </span>
      <span slot="homePage" slot-scope="text">
        {{text===0?"否":'是'}}
      </span>
      <span slot="issue" slot-scope="text">
        {{text===0?"禁用":'正常'}}
      </span>
      <span slot="isIndex" slot-scope="text">
        {{ text }}
      </span>
      <div slot="action" slot-scope="record">
        <span>
          <router-link :to="{name:'allocationAddOrEdit',query:{id:record.id}}">编辑</router-link>
          <a-divider type="vertical" />
          <a @click="soldUpDown(record)">
            {{ record.issue ===0?"上架":"下架"}}
          </a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除这个页面吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm(record.id)"
            @cancel="cancel"
          >
            <a href="#">删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a>设置首页</a>
        </span>
      </div>
    </a-table>
    <br />
    <!-- 分页 -->
    <a-pagination
      show-size-changer
      show-quick-jumper
      style="text-align: center"
      :total="total"
      :show-total="
        (total, range) => `共${total}条记录 第${range[0]}-${range[1]}条数据`
      "
      :page-size="pageSize"
      v-model="current"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    />
    <!-- 弹窗口 -->
    <a-modal v-model="visible" title="新增/编辑" @ok="handleOk">
      <ColumEdit></ColumEdit>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: '页面设计',
          url: '/allocation/list'
        },
        {
          name: '页面列表',
          url: '/allocation/list'
        }
      ],
      columns: [
        {
          title: '页面名称',
          dataIndex: 'name',
          key: 'name',
          width: 150,
          ellipsis: true
        },
        {
          title: '页面编号',
          dataIndex: 'code',
          key: 'code',
          width: 150,
          ellipsis: true
        },
        {
          title: '是否首页',
          key: 'homePage',
          dataIndex: 'homePage',
          width: 100,
          ellipsis: true,
          scopedSlots: {
            customRender: 'homePage'
          }
        },
        {
          title: '状态',
          key: 'issue',
          dataIndex: 'issue',
          width: 100,
          ellipsis: true,
          scopedSlots: {
            customRender: 'issue'
          }
        },
        {
          title: '页面描述',
          dataIndex: 'memo',
          key: 'memo'
        },
        {
          title: '修改时间',
          dataIndex: 'gmtModified',
          key: 'gmtModified',
          width: 180,
          ellipsis: true
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      visible: false,
      formState: {
        name: '',
        code: undefined

      },
      data: [],
      total: 0,
      pageSize: 10,
      current: 1
    }
  },
  created () {
    this.queryData()
  },
  methods: {
    // 下架商品
    async soldUpDown (record) {
      const res = await this.$store.dispatch('http', {
        api: 'getManagePageIssue',
        query: {
          pageId: record.id
        }
      })
      console.log(res)
      if (res) {
        // this.queryData(10) // 传入数字 重置列表不会更改当前页
        this.queryData() // 不传数字 会刷新当前页为1  按时间排序 更新第一页会看到财编辑的页面
      }
    },
    // 删除页面
    async deletePage (id) {
      console.log('deletePage', id)
      const res = await this.$store.dispatch('http', {
        api: 'getManagePageDelete',
        query: {
          pageId: id
        }
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '删除页面成功'
        })
        // this.queryData(10) // 传入数字 重置列表不会更改当前页
        this.queryData() // 不传数字 会刷新当前页为1  按时间排序 更新第一页会看到财编辑的页面
      }
    },
    async queryData (pageNumber) {
      // 如果不是点击的分页  而是点的查询按钮  需要修改  当前页current
      this.current = pageNumber ? this.current : 1
      console.log(this.current, this.pageSize)
      const res = await this.$store.dispatch('http', {
        api: 'getManagePage',
        params: {
          ...this.formState,
          pageNo: this.current,
          pageSize: this.pageSize
        }
      })
      console.log(res)

      this.data = res.records
      this.total = res.total
      this.$forceUpdate()
    },
    handleFinish () {
    },
    handleFinishFailed () {
      console.log()
    },
    resetForm () {
      this.formState = {
        name: '',
        code: undefined
      }
      this.queryData()
    },
    // 改变页码
    onPageChange (pageNumber, pageSize) {
      this.queryData(event, pageNumber)
    },
    // 改变页码
    onShowSizeChange (current, pgSize) {
      console.log(pgSize)
      this.pageSize = pgSize
      this.queryData()
    },
    // 是否确认弹窗口  方法
    confirm (id) {
      this.deletePage(id)
    },
    cancel (values) {
      console.log(values)
    },
    // 弹出窗口
    showModal () {
      // Message['success']("adafd",3)
      this.visible = true
    },
    handleOk (values) {
      console.log(values, this.formState)
      this.visible = false
    }
  }
}
</script>

<style>
</style>
